:root {
    --primary: #f58a33;
    --secondary: #717274;
    --tertiary: #dee2e6;
}

body {
  background: #FFF;
}

.modal.show {
  display: block;
}

.text-bold {
    font-weight: bold;
  }
  
  * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  .owl-carousel.owl-loaded.owl-drag .owl-item a {
    display: block !important;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  p {
    font-size: 13px;
  }
  
  input {
    font-size: 11px;
  }
  
  button.animated-plus-minus {
    display: block;
    position: relative;
    width: 15px;
    height: 15px;
    padding: 10px !important;
  }
  
  button.animated-plus-minus span.stroke {
    position: absolute;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    width: 15px;
    height: 2px;
    background-color: #f58a33;
    display: block;
  }
  
  button.animated-plus-minus span.stroke.stroke2 {
    top: 50%;
    -webkit-transform: translate(-7.5px, 0);
            transform: translate(-7.5px, 0);
  }
  
  button.animated-plus-minus span.stroke.stroke1 {
    -webkit-transform: rotate(90deg) translate(0, 7.5px);
            transform: rotate(90deg) translate(0, 7.5px);
  }

  button.animated-plus-minus.collapsed span.stroke.stroke1 {
    left: 50%;
    -webkit-transform: rotate(0deg) translate(-7.5px, 0px);
            transform: rotate(0deg) translate(-7.5px, 0px);
  }
  
  header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 200;
  }
  
  header .top-balk {
    background-color: #f58a33;
    height: 20px;
  }
  
  header .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #F6F6F6;
    padding: 2rem;
  }
  
  header .header > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  header .header > div .search-form form {
    background: #ffffff;
  }
  
  header .header > div .search-form form input {
    background: none;
    border: 1px solid #ececec;
    border-right: none;
    padding: 0.6rem;
    height: 100%;
  }
  
  header .header > div .search-form form input:focus {
    outline: none;
  }
  
  header .header > div .search-form button {
    border: none;
    background: none;
    padding: 0;
    color: #f58a33;
    padding: 0.6rem 1.2rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: solid 1px #ececec;
    background: #ffffff;
  }
  
  header .header > div .cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  header .header > div .cart > div {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  header .header > div .cart > div a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #525252;
  }
  
  header .header > div .cart > div a:hover {
    text-decoration: none;
  }
  
  header .header > div .cart > div a div.border-circle {
    border-radius: 50%;
    color: #ffffff;
    background: #f58a33;
    width: 25px;
    height: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: 25px;
    font-size: 10px;
    margin-right: 10px;
    margin-left: 2px;
  }
  
  header .header > div .cart > div a i {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  header .header > div .cart > div a i.caret {
    color: #f58a33;
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
  
  header .header > div p {
    margin-bottom: 0;
  }
  
  main section.breadcrumbs {
    margin-top: 1rem;
    padding-left: 40px;
    padding-right: 40px;
  }
  
  main section.breadcrumbs i {
    font-size: 8px;
  }
  
  main section.breadcrumbs li a {
    color: #858585;
    font-size: 13px;
    white-space: nowrap;
  }
  
  main section.breadcrumbs li.active a {
    color: #f58a33;
  }
  
  main > div {
    margin-top: 1rem;
  }
  
  main div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  main div .side-nav {
    font-weight: bold;
  }
  
  main div .side-nav ul {
    list-style: none;
    padding: 0;
    width: 100%;
  }
  
  main div .side-nav ul li {
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #e1e1e1;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  
  main div .side-nav ul li a {
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    color: #525252;
    font-size: 13px;
    text-decoration: none;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  main div .side-nav ul li a:hover {
    color: #f58a33;
  }
  
  main div .side-nav ul li a p {
    margin: 0;
  }
  
  main div .side-nav ul li button {
    border: none;
    background: none;
    padding: 0;
    color: #f58a33;
    padding: 0;
    margin-left: auto;
  }
  
  main div .side-nav ul ul.sub-menu {
    display: none;
    margin: 1rem 0 0 -0.5rem !important;
    border-top: 1px solid #e1e1e1;
    padding-left: 0.5rem;
  }

  main div .side-nav ul ul.sub-menu.show { 
    display: block;
  }
  
  main div .side-nav ul ul.sub-menu li {
    border-bottom: none;
    font-weight: 500;
    padding: 0.4rem;
    padding-left: 0;
    margin-top: 0;
  }
  
  main div section.categories div.categories {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 32% 32% 32%;
        grid-template-columns: 32% 32% 32%;
    grid-gap: 2%;
  }
  
  main div section.categories div.categories a {
    height: 230px;
    position: relative;
    color: #ffffff;
    background-position: center;
    background-size: cover;
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  main div section.categories div.categories a p {
    position: absolute;
    bottom: 0;
    left: 20px;
    background-color: #f58a33;
    padding: 0.2rem 0.4rem;
  }
  
  footer {
    background: #F6F6F6;
    margin-top: 3rem;
  }
  
  footer > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 3rem;
  }
  
  footer > div h3 {
    color: #f58a33;
    font-size: 18px;
  }
  
  footer > div ul {
    list-style: none;
  }
  
  footer > div ul li {
    margin-bottom: 4px;
  }
  
  footer > div ul a {
    color: #525252;
    font-size: 13px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  
  footer > div ul a:hover {
    text-decoration: none;
    color: #f58a33;
  }
  
  section.products div.filters {
    margin-left: -1rem;
  }
  
  section.products div.filters form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
        flex-flow: wrap;
    margin-bottom: 2rem;
  }
  
  section.products div.filters form > label {
    margin: 0 1.2rem;
    padding: 0.25rem;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  section.products div.filters form .dropdown {
    position: relative;
  }
  
  section.products div.filters form .dropdown > div:first-child {
    margin: 0.25rem 1.2rem;
    padding: 0.25rem 0.75rem;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  section.products div.filters form .dropdown > div:first-child p {
    margin: 0;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  section.products div.filters form .dropdown > div:first-child i {
    margin-left: 2.5rem;
    color: #f58a33;
  }
  
  section.products div.filters form .dropdown > div:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 100%;
    background: #F6F6F6;
    padding: 1rem;
    border: 1px solid #e1e1e1;
    z-index: 10;
    margin-left: 1.25rem;
    display: none;
    visibility: 0;
  }
  
  section.products div.filters form .dropdown > div:last-child .option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    white-space: nowrap;
  }
  
  section.products div.filters form .dropdown > div:last-child .option label {
    margin: 0;
    position: relative;
    margin-left: 1.5rem;
  }
  
  // section.products div.filters form .dropdown > div:last-child .option label:before {
  //   content: '';
  //   position: absolute;
  //   left: -15%;
  //   top: 50%;
  //   -webkit-transform: translate(-100%, -50%);
  //           transform: translate(-100%, -50%);
  //   width: 15px;
  //   height: 15px;
  //   background: #ffffff;
  //   border: 1px solid #525252;
  // }

  section.products div.filters form .dropdown .dropdown-items.show {
    display: block;
  }
  
  section.products .category-navigation .border-top {
    border-color: #e1e1e1;
  }
  
  section.products .category-navigation .filter-type {
    position: relative;
  }
  
  section.products .category-navigation .filter-type button {
    border: none;
    background: none;
    padding: 0;
    border: 1px solid #e1e1e1;
    padding-left: 0.5rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 13px;
  }
  
  section.products .category-navigation .filter-type button i {
    background-color: #F6F6F6;
    color: #f58a33;
    border-left: 1px solid #e1e1e1;
    padding: 0.5rem;
    margin-left: 1rem;
  }
  
  section.products .category-navigation .filter-type div.filters {
    display: none;
    margin-left: 0;
    position: absolute;
    z-index: 10;
    top: 100%;
    width: 100%;
  }

  section.products .category-navigation .filter-type div.filters.show { 
    display: block;
  }
  
  section.products .category-navigation .filter-type div.filters ul {
    list-style-type: none;
    position: absolute;
    left: 0;
    top: 100%;
    padding-left: 0;
    z-index: 10;
    width: 100%;
    background-color: #F6F6F6;
    border: 1px solid #e1e1e1;
  }
  
  section.products .category-navigation .filter-type div.filters ul li a {
    color: #525252;
    border-bottom: 1px solid #e1e1e1;
    display: block;
    padding: 0.2rem 0.5rem;
  }
  
  section.products .category-navigation .filter-type div.filters ul li a:hover {
    text-decoration: none;
    color: #ffffff;
    background: #f58a33;
  }
  
  section.products .category-navigation .filter-type div.filters ul li:last-child a {
    border: none;
  }
  
  section.products .category-navigation .pagination ul {
    margin-bottom: 0;
  }
  
  section.products .category-navigation .pagination ul li {
    background: #F6F6F6;
    margin: auto 0.2rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  section.products .category-navigation .pagination ul li.active {
    font-weight: bold;
  }
  
  section.products .category-navigation .pagination ul li a {
    display: block;
    font-size: 13px;
    color: #525252;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    padding: .8rem 1rem;
  }
  
  section.products .category-navigation .pagination ul li a i {
    color: #f58a33;
  }
  
  section.products .category-navigation .pagination ul li a:hover {
    text-decoration: none;
    color: #f58a33;
  }
  
  section.products .category-navigation .current-visible p {
    font-size: 13px;
    color: #f58a33;
    font-weight: bold;
    margin-bottom: 0;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    padding: 0 1rem;
  }
  
  section.products .category-navigation .view-type p {
    font-size: 13px;
    margin-bottom: 0;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    color: #525252;
    margin-right: 0.5rem;
  }
  
  section.products .products {
    margin-top: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  section.products .products article {
    max-height: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background-color: #F6F6F6;
    width: 100%;
  }

  section.products .products article.margin-bottom {
    margin-bottom: 300px;
  }
  
  section.products .products article > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    color: #525252;
    position: relative;
  }

  section.products .products article a {
    color: #525252;
  }
  
  section.products .products article a:hover {
    text-decoration: none;
  }

  article .product-link.image-link {
    width: 15%;
  }

article .product-link.description-link {
    width: 40%;
  }
  
  section.products .products article .image {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: block;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  section.products .products article .image span.discounted {
    position: absolute;
    left: 4px;
    top: 4px;
    background: #f58a33;
    color: #ffffff;
    padding: 0.2rem;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
  }
  
  section.products .products article > a {
    margin: 0.2rem;
  }
  
  section.products .products article > a img {
    width: 100%;
    height: auto;
    margin-right: 1rem;
  }
  
  section.products .products article h3 {
    font-size: 18px;
  }
  
  section.products .products article p {
    font-size: 12px;
  }
  
  section.products .products article .description {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto 0;
    margin-left: 1rem;
  }
  
  section.products .products article .variants {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: 2rem;
  }
  
  section.products .products article .variants button {
    border: none;
    background: none;
    padding: 0;
    text-decoration: underline;
    color: #525252;
    font-size: 11px;
    letter-spacing: 0.1em;
    font-weight: 600;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  
  section.products .products article .variants button i {
    color: #f58a33;
    margin-right: 0.5rem;
  }
  
  section.products .products article .variants button:hover {
    color: #f58a33;
  }
  
  section.products .products article .price-information {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto 0;
    margin-right: 0.5rem;
  }
  
  section.products .products article .price-information > div:first-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  section.products .products article .price-information > div:first-child p.price {
    font-size: 20px;
    font-weight: bold;
    text-align: right;
  }
  
  section.products .products article .price-information > div:first-child p.price span.price-old {
    color: #f58a33;
    font-size: 12px;
    text-decoration: line-through;
  }
  
  section.products .products article .price-information > div:first-child p.tax {
    font-size: 11px;
    color: #a2a2a2;
    text-align: right;
  }
  
  section.products .products article .price-information div form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  
  section.products .products article .price-information div form input {
    border: none;
    max-width: 40px;
    text-align: center;
    color: #525252;
  }
  
  section.products .products article .price-information div form input:focus {
    outline: none;
  }
  
  section.products .products article form a {
    border: none;
    background: none;
    color: #f58a33;
    background-color: #F6F6F6;
    padding: 0.5rem 0.9rem;
    cursor: pointer;
  }
  
  section.products .products article .price-information div form button.cart {
    margin: 0 0.4rem;
    margin-right: 0;
    background-color: #18a266;
    color: #ffffff;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    padding: 0.5rem 0.9rem;
  }
  
  section.products .products article .price-information div form button.cart:hover {
    background-color: #19d588;
  }
  
  section.products .products article .price-information div form div:first-child {
    border: 1px solid #e1e1e1;
  }
  
  section.products .products article .variants-list {
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: none;
    margin-left: -3px;
    margin-right: 3px;
  }

  section.products .products article .variants-list.show { 
    display: block;
  }
  
  section.products .products article .variants-list div.variant {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 2px solid #F6F6F6;
    width: 100%;
    margin: 0;
    padding-top: 0.5rem;
  }
  
  section.products .products article .variants-list div.variant > p {
    font-size: 12px;
    padding: 10px 20px;
    width: 40%;
    vertical-align: middle;
    color: #525252;
  }
  
  section.products .products article .variants-list div.variant .price {
    font-weight: bold;
    font-size: 14px;
  }
  
  section.products .products article .variants-list div.variant .price span {
    font-weight: normal;
    font-size: 12px;
    color: #aaa;
  }
  
  section.products .products article .variants-list div.variant form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  section.products .products article .variants-list div.variant form div:first-child {
    border: 1px solid #e1e1e1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  section.products .products article .variants-list div.variant form button {
    border: none;
    background: none;
    padding: 0;
    background-color: #F6F6F6;
    padding: 0.5rem 0.9rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #f58a33;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  
  section.products .products article .variants-list div.variant form button.cart {
    color: #ffffff;
    background-color: #18a266;
    border: 1px solid #18a266;
    margin: 0 0.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  section.products .products article .variants-list div.variant form button.cart:hover {
    background-color: #19d588;
    border-color: #19d588;
  }
  
  section.products .products article .variants-list div.variant form input {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0.2rem 0.5rem;
    border: none;
    text-align: center;
    width: 40px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  section.products .products article .variants-list div.variant form input:focus {
    outline: none;
  }
  
  section.products .category-description div {
    padding: 0;
  }
  
  section.products .category-description .description {
    margin-left: 2rem;
  }
  
  section.products .category-description .description h3 {
    font-size: 1.4rem;
  }
  
  section.products .category-description img {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  section.product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  section.product span.discounted {
    position: absolute;
    left: 4px;
    top: 4px;
    background: #F73400;
    z-index: 10;
    color: #ffffff;
    padding: 0.2rem;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 12px;
  }
  
  section.product > div:last-child {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  section.product > div:last-child .product-header {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 1rem;
    display: block;
  }
  
  section.product > div:last-child .product-header h2 {
    display: block;
    width: 100%;
  }
  
  section.product > div:last-child .product-header p.in-stock {
    color: #f58a33;
    text-align: right;
    font-weight: bold;
    font-size: 0.7rem;
  }
  
  section.product > div:last-child .product-header p.in-stock i {
    margin-right: 0.5rem;
  }
  
  section.product > div:last-child .general {
    display: block;
    border-bottom: 1px solid #e1e1e1;
  }
  
  section.product > div:last-child .general .article-code {
    font-weight: bold;
    color: #525252;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  section.product > div:last-child .general .article-code span {
    margin-left: 0.5rem;
    font-weight: normal;
  }
  
  section.product > div:last-child .general .price {
    font-size: 18px;
    font-weight: bold;
    color: #525252;
  }
  
  section.product > div:last-child .general .price span.price-old {
    color: #f58a33;
    text-decoration: line-through;
    font-size: 12px;
  }
  
  section.product > div:last-child .general .price span {
    margin-left: 0.2rem;
    font-size: 11px;
  }
  
  section.product > div:last-child .variants {
    display: block;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  
  section.product > div:last-child .variants div {
    display: block;
  }
  
  section.product > div:last-child .variants div p {
    display: block;
    font-weight: bold;
    width: 100%;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  
  section.product > div:last-child .variants div .filter-type {
    height: 40px;
  }
  
  section.product > div:last-child .variants div .filter-type button {
    border: none;
    background: none;
    padding: 0;
    border: 1px solid #e1e1e1;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 0.5rem;
    color: #525252;
  }
  
  section.product > div:last-child .variants div .filter-type button i {
    padding: 0.75rem;
    background: #F6F6F6;
    color: #f58a33;
    font-size: 14px;
  }
  
  section.product > div:last-child .variants div .filter-type .filters {
    z-index: 1;
    position: relative;
  }
  
  section.product > div:last-child .variants div .filter-type .filters ul {
    list-style: none;
    padding-left: 0;
    border: 1px solid #e1e1e1;
    width: 100%;
    z-index: 1;
  }
  
  section.product > div:last-child .variants div .filter-type .filters ul li {
    background: #F6F6F6;
  }
  
  section.product > div:last-child .variants div .filter-type .filters ul li a {
    display: block;
    color: #525252;
    padding: .5rem;
  }
  
  section.product > div:last-child .variants div .filter-type .filters ul li a:hover {
    text-decoration: none;
  }
  
  section.product > div:last-child .cart form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
  }
  
  section.product > div:last-child .cart form div:first-child {
    border: 1px solid #e1e1e1;
  }
  
  section.product > div:last-child .cart form div:first-child a {
    border: none;
    background: none;
    padding: 0;
    background: #F6F6F6;
    padding: 0.8rem;
    color: #f58a33;
    font-weight: bold;
    cursor: pointer;
  }
  
  section.product > div:last-child .cart form div:first-child input {
    border: none;
    width: 50px;
    text-align: center;
  }
  
  section.product > div:last-child .cart form div:first-child input:focus {
    outline: none;
  }
  
  section.product > div:last-child .cart form div:last-child {
    width: 65%;
    margin-left: 0.5rem;
  }
  
  section.product > div:last-child .cart form div:last-child button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    background: none;
    padding: 0;
    background: #18a266;
    color: #ffffff;
    font-size: 0.8rem;
    padding: 0.8rem 1rem;
  }
  
  section.product > div:last-child .cart form div:last-child button i {
    margin-right: 0.4rem;
  }
  
  section.product-information {
    margin-top: 1rem;
  }
  
  section.product-information nav {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  section.product-information nav ul {
    padding-left: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
  }
  
  section.product-information nav ul li {
    margin-right: 0.5rem;
  }
  
  section.product-information nav ul li a {
    color: #525252;
    font-size: 0.8rem;
  }
  
  section.product-information nav ul li a:hover {
    text-decoration: none;
    color: #f58a33;
  }
  
  section.product-information nav ul li.active a {
    font-weight: bold;
  }
  
  section.product-information div.information > div {
    display: none;
    margin-top: 2rem;
    width: 100%;
  }
  
  section.product-information div.information > div.active {
    display: block;
  }
  
  section.product-information div.information > div table {
    width: 100%;
  }
  
  section.product-information div.information > div table tr:nth-child(2n) {
    background: #F6F6F6;
  }
  
  section.product-information div.information > div table td, section.product-information div.information > div table strong {
    font-size: 0.8rem;
    margin-right: 1rem;
    padding: 0.4rem;
  }
  
  section.related-products h3 {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }
  
  section.related-products > div {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  section.related-products a {
    color: #525252;
  }
  
  section.related-products a:hover {
    text-decoration: none;
  }
  
  section.related-products a:hover p.product-title {
    color: #f58a33;
  }
  
  section.related-products a:hover .product-buttons {
    opacity: 1;
  }
  
  section.related-products a img {
    border: 1px solid #f3f3f3;
  }
  
  section.related-products a > div {
    background-color: #fbfbfb;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0.8rem;
  }
  
  section.related-products a .product-title {
    font-size: 1rem;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  
  section.related-products a .price {
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: auto;
  }
  
  section.related-products a .bottom-row {
    color: #a2a2a2;
  }
  
  section.related-products a .bottom-row .vat-price {
    font-weight: 300;
  }
  
  section.related-products a .bottom-row button {
    border: none;
    background: none;
    padding: 0;
    color: #a2a2a2;
  }
  
  section.related-products a .bottom-row i {
    font-size: 0.8rem;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  section.related-products a .product-buttons {
    background-color: unset;
    opacity: 0;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  
  section.related-products a .product-buttons button {
    border: none;
    background: none;
    padding: 0;
    color: #ffffff;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  
  section.related-products a .product-buttons button.btn-info {
    background-color: #383838;
    width: 75%;
    margin-right: 0.5rem;
    padding: 0.3rem;
    font-size: 0.8rem;
  }
  
  section.related-products a .product-buttons button.btn-info:hover {
    opacity: 0.85;
  }
  
  section.related-products a .product-buttons button.btn-cart {
    background-color: #18a266;
    width: 25%;
    padding: 0.3rem;
    font-size: 0.6rem;
  }
  
  section.related-products a .product-buttons button.btn-cart:hover {
    background-color: #19d588;
  }
  
  section.bundels {
    background-color: #ffffff;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  section.bundels h3 {
    font-weight: 500;
    color: #525252;
  }
  
  section.bundels .products {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  section.bundels .products i {
    color: #f58a33;
    font-size: 1.6rem;
  }
  
  section.bundels .products img {
    width: 150px;
    margin: 0 1rem;
  }
  
  section.bundels article {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  
  section.bundels article .bundel-extra img {
    width: 70px;
  }
  
  section.bundels article .bundel-extra a {
    color: #333333;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  
  section.bundels article .bundel-extra a:hover {
    color: #f58a33;
    text-decoration: none;
  }
  
  section.bundels article .bundel-information {
    margin-top: 1rem;
  }
  
  section.bundels article .bundel-information .title {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
  
  section.bundels article .bundel-information .discount {
    color: #18a266;
    font-weight: bold;
  }
  
  section.bundels article .bundel-information div {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 0.4rem;
  }
  
  section.bundels article .bundel-information div .price-old {
    color: #f58a33;
    text-decoration: line-through;
  }
  
  section.bundels article .bundel-information div .price {
    font-weight: bold;
    font-size: 1.4rem;
  }
  
  section.bundels article .bundel-information div .price-vat {
    color: #a2a2a2;
  }
  
  section.bundels article .bundel-information button.cart {
    border: none;
    background: none;
    padding: 0;
    background-color: #18a266;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    color: #ffffff;
    padding: 0.6rem;
  }
  
  section.bundels article .bundel-information button.cart:hover {
    background-color: #19d588;
  }
  
  .cart-page {
    color: #525252;
  }
  
  .cart-page table {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  
  .cart-page table th {
    min-width: 100px;
    padding: 0.4rem;
    font-size: 13px;
    color: #707273;
  }
  
  .cart-page table tr {
    border: 1px solid #F5F5F5;
  }
  
  .cart-page table tr td {
    padding: 15px 0 15px 15px;
  }
  
  .cart-page table tr:last-child {
    border-bottom: 1px solid #CCCCCC;
  }
  
  .cart-page table tr td:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  
  .cart-page table tr td:first-child div:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .cart-page table tr td:first-child div:last-child p.title {
    font-weight: 600;
  }
  
  .cart-page table img {
    width: 50px;
    margin-right: 1rem;
  }
  
  .cart-page p {
    margin: 0;
  }
  
  .cart-page .cart-amount > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .cart-page .cart-amount input {
    width: 30px;
    border: 1px solid #e9e9e9;
    text-align: center;
  }
  
  .cart-page .cart-amount input:focus {
    outline: none;
    border: 1px solid #e9e9e9;
  }
  
  .cart-page .cart-amount .cart-buttons {
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .cart-page .cart-amount .cart-buttons button {
    background-color: #f1f1f1;
    border: 1px solid #e9e9e9;
    border-left: none;
    color: #6e6e6e;
    padding: 0;
    line-height: 100%;
    width: 15px;
    height: 13px;
  }
  
  .cart-page .cart-amount .remove {
    border: none;
    background: none;
    padding: 0;
    background-color: #cdcdcd;
    color: #ffffff;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 0.6rem;
  }
  
  .cart-page .cart-amount .remove i {
    margin: auto;
    font-size: 60%;
  }
  
  .cart-page .cart-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 3rem;
  }
  
  .cart-page .cart-buttons a {
    color: #f58a33;
    font-size: 0.7rem;
  }
  
  .cart-page .cart-buttons a:hover {
    text-decoration: none;
  }
  
  .cart-page .cart-buttons button {
    border: none;
    background: none;
    padding: 0;
    background: #f58a33;
    color: #ffffff;
    padding: 0.4rem 1rem;
    font-size: 0.7rem;
    border-radius: 2px;
  }
  
  .cart-page .order-information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
  }
  
  .cart-page .order-information p {
    font-weight: bold;
    text-align: right;
  }
  
  .cart-page .order-information p span {
    text-align: right;
    float: right;
    width: 25%;
    width: 150px;
  }
  
  .cart-page .order-information .vat {
    font-weight: normal;
  }
  
  .cart-page .order-information .total {
    margin-top: 1rem;
    font-size: 1rem;
  }
  
  .cart-page .order-information button {
    border: none;
    background: none;
    padding: 0;
    background: #18a266;
    color: #ffffff;
    padding: 0.4rem 1.4rem;
    border-radius: 2px;
    margin-top: 2rem;
    margin-left: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .textpage .side-nav ul {
    padding-left: 9px;
  }
  
  .textpage .side-nav li {
    padding: 0;
    border: none;
    font-weight: normal;
  }
  
  .textpage .side-nav li a.active {
    color: #f58a33;
  }
  
  .textpage .content h1 {
    font-size: 25px;
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .textpage .content h2 {
    font-size: 21px;
    margin-bottom: 1.4rem;
  }
  
  .textpage .content h3 {
    font-size: 18px;
    margin-bottom: 1.2rem;
  }
  
  .textpage .content h4 {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .loader-background {
    width: 100%;
    background: #FFF;
    text-align: center;
    display: block;
    padding: 0;
    overflow: hidden;
    max-height: 1000px;
  }

  .loader-background > img {
    display:inline-block;
    padding-top:50px;
  }

  .loader-background > div {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .loader-background p {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 1rem 0 0;
  }

  #keyword {
    padding-left:10px;
    border: 1px solid #EEE !important;
    border-right: 0px !important;
  }

  @import 'mobile.scss';