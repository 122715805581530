@media (max-width: 992px) and (min-width: 767px) {
    .container {
      max-width: 100%;
    }
  }
  
  @media (min-width: 767px) {
    .touch-only {
      display: none !important;
    }
  }
  
  @media (max-width: 767px) {
    .hide-mobile {
      display: none !important;
    }
    .menu-background.active {
      background: #000000;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 10;
      opacity: 0.6;
    }
    .mobile-menu {
      position: absolute;
      left: 0;
      top: 0;
      background: #ffffff;
      z-index: 20;
      height: 100vh;
      overflow: hidden;
      -webkit-transition: 0.4s ease-in-out;
      transition: 0.4s ease-in-out;
      width: 60vw;
      margin-left: -60vw;
      overflow: visible;
    }
    .mobile-menu .close-menu {
      position: absolute;
      left: 100%;
      background: #f58a33;
      color: #ffffff;
      padding: 0;
      height: 50px;
      width: 50px;
      display: none;
    }
    .mobile-menu img {
      max-width: 200px;
      margin-bottom: 1rem;
      padding: 1rem;
    }
    .mobile-menu ul {
      list-style: none;
      padding-left: 0;
    }
    .mobile-menu a {
      padding-left: 1rem;
      border: 3px solid transparent;
      border-block-style: none;
      padding: 0.8rem;
      display: inline-block;
    }
    .mobile-menu button {
      color: #f58a33;
      margin-left: auto;
      padding-right: 1rem;
    }
    .mobile-menu .has-sub {
      padding-left: 0rem;
    }
    .mobile-menu .has-sub ul {
      display: none;
      margin-left: 1rem;
      border-left: 3px solid #f58a33;
    }
    .mobile-menu .has-sub ul li {
      padding: 1rem 0;
    }
    .mobile-menu .has-sub ul li a {
      padding-top: 0;
      padding-bottom: 0;
    }
    .mobile-menu .has-sub > div:first-child {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .mobile-menu .has-sub.active > div:first-child {
      background: #e1e1e1;
    }
    .mobile-menu .has-sub.active > div:first-child a {
      border-left: 3px solid #f58a33;
    }
    .mobile-menu a {
      color: #3d3d3d;
    }
    .mobile-menu a:hover {
      color: #3d3d3d;
      opacity: 1;
    }
    .mobile-menu.active {
      margin-left: 0;
    }
    header .header {
      padding: 0;
    }
    header .header .container {
      padding: 1rem 1rem;
      position: relative;
    }
    header .top-balk {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }
    header .top-balk div div div:first-child {
      -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center;
    }
    header .top-balk div div div:last-child {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    header .top-balk div div div:last-child div {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }
    header a {
      padding: 0.8rem;
    }
    header a.cart {
      position: relative;
    }
    header .cart-count {
      position: absolute;
      top: 2px;
      right: 2px;
      background: #f58a33;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 10px;
    }
    header .phone {
      font-size: 0.8rem;
    }
    header .phone i {
      font-size: initial;
    }
    header a {
      color: #ffffff;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
    }
    header a:hover {
      color: #ffffff;
      opacity: 0.75;
      text-decoration: none;
    }
    header button {
      border: none;
      background: none;
      padding: 0;
      font-size: 1.2rem;
    }
    header button i.fa-search {
      color: #f58a33;
    }
    header .search-form {
      position: absolute;
      top: 100%;
      z-index: 1;
      width: 100%;
      max-width: unset;
      left: 0;
      right: 0;
      padding: 0 1.9rem;
      display: none !important;
    }
    header .search-form.active {
      display: block !important;
    }
    header .search-form form {
      width: 100%;
    }
    header .search-form input {
      width: 100%;
      font-size: 1rem;
      border: none !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }
    footer div.container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      text-align: center;
    }
    footer div.container ul {
      margin-bottom: 2rem;
      padding: 0;
    }
    app-sidebar, .side-nav {
      position: absolute;
      left: 0;
      top: 0;
      height: 100vh;
      background: #ffffff;
      z-index: 10;
      max-width: unset;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      display: none;
    }
    .side-nav.active {
      display: block;
    }
    main div section.categories div.categories {
      -ms-grid-columns: 49% 49%;
          grid-template-columns: 49% 49%;
    }
    main div section.categories div.categories a p {
      bottom: 10px;
      left: 0;
      right: 0;
      width: 80%;
      margin: auto;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
    }
    section.products form .mobile-category-filters button {
      border: none;
      background: none;
      padding: 0;
    }
    section.products form .mobile-category-filters button.show-filters {
      background: #F6F6F6;
      padding: 0 1rem;
      border: 1px solid #e1e1e1;
      font-weight: 500;
      color: #525252;
      font-size: 14px;
    }
    section.products form .mobile-category-filters button.show-filters i {
      color: #f58a33;
      font-size: 10px;
      margin-left: 0.5rem;
    }
    section.products form .mobile-category-filters button.dropdown-button {
      border: 1px solid #e1e1e1;
      padding-left: 0.5rem;
      color: #525252;
    }
    section.products form .mobile-category-filters button.dropdown-button i {
      padding: 0.6rem;
      margin-left: 1rem;
      background: #F6F6F6;
      color: #f58a33;
      font-size: 10px;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items {
      display: none;
      position: absolute;
      left: 0;
      margin: 0;
      top: 100%;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      z-index: 1;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items.show {
      display: block;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items ul li a {
      padding: 1rem;
      display: block;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items ul li.selected {
      background: #F6F6F6;
      color: #3d3d3d;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items ul li.selected a {
      font-weight: 500;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items ul li:hover {
      background: #f58a33;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items ul li:hover a {
      color: #ffffff;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items ul a {
      white-space: nowrap;
      color: #525252;
    }
    section.products form .mobile-category-filters .filter-type.dropdown .filters.dropdown-items ul a:hover {
      text-decoration: none;
    }
    section.products form div.mobile-filter-dropdown {
      display: none;
      margin-top: 2rem;
      overflow: hidden;
      padding: 0 0.5rem;
    }
    section.products form div.mobile-filter-dropdown.show {
      display: block
    }
    section.products form div.mobile-filter-dropdown .form-row h3 {
      font-size: 0.8rem;
      color: #525252;
    }
    section.products form div.mobile-filter-dropdown .form-row .form-check {
      padding-left: 0;
    }
    section.products form div.mobile-filter-dropdown .form-row span.checkmark {
      position: absolute;
      left: 0;
      top: 50%;
      height: 17px;
      width: 17px;
      border: 1px solid #e1e1e1;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      color: #ffffff;
    }
    section.products form div.mobile-filter-dropdown .form-row span.checkmark.checked {
      background: #f58a33;
    }
    section.products form div.mobile-filter-dropdown .form-row span.checkmark:hover {
      cursor: pointer;
    }
    section.products form div.mobile-filter-dropdown .form-row span.checkmark i {
      display: block;
      font-size: 8px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
    }
    section.products form div.mobile-filter-dropdown .form-row label {
      margin-left: 0.5rem;
    }
    section.products .products article:nth-child(2n) {
      background: #FCFCFC;
    }
    section.products .products article {
      max-height: unset;
    }
    section.products .products article a {
      display: block;
    }
    section.products .products article div:not(.image-link), section.products .products article a:not(.image-link) {
      float: right;
    }
    section.products .products article .image-link {
      width: 33%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      float: left;
      height: 100%;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background: #ffffff;
    }
    section.products .products article .image-link img {
      width: 100%;
      height: auto;
    }
    section.products .products article .description-link {
      display: inline-block;
      width: 60%;
    }
    section.products .products article .description-link p {
      margin-bottom: 0;
    }
    section.products .products article .variants {
      width: 60%;
      display: none;
    }
    section.products .products article .mobile-variants {
      display: block;
      width: 60%;
    }
    section.products .products article .variants-list {
      margin: 0 -3px;
    }
    section.products .products article .variants-list .variant {
      display: block;
    }
    section.products .products article .variants-list .variant p {
      display: inline-block;
      margin-bottom: 0;
    }
    section.products .products article .variants-list .variant p:first-child {
      display: block;
      width: 100%;
    }
    section.products .products article .variants-list .variant form {
      float: right;
      margin-top: 0.5rem;
    }
    section.products .products article .variants-list .variant .tax {
      display: block;
    }
    section.products .products article .price-information {
      width: 60%;
    }
    section.products .products article .price-information p {
      text-align-last: left;
      margin-bottom: 0;
    }
    section.products .category-description {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
    section.products .category-description div:first-child {
      max-width: unset;
      width: 100%;
    }
    section.products .category-description .description {
      margin-top: 1rem;
      margin-left: 0;
    }
    section.product {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
    .product-information.touch-only .info-tab {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0.5rem;
      padding: 0.5rem 0;
      border-bottom: 1px solid #e1e1e1;
    }
    .product-information.touch-only .content {
      display: none;
      overflow: hidden;
    }
    .product-information.touch-only .content.show { 
      display: block;
    }
    .product-information.touch-only button {
      border: none;
      background: none;
      padding: 0;
    }
    div.container .product > div {
      width: 100% !important;
    }
    div.container .product .description {
      display: none;
    }
    div.container .product .general > div {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse;
    }
    div.container .related-products .products {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
    div.container .related-products .products .product-buttons {
      opacity: 1;
      padding: 0.8rem 0;
      margin-bottom: 1rem;
    }
    div.container .related-products .products .product-description {
      display: none;
    }
    div.container .bundels h3 {
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
    div.container .bundels article {
      margin-bottom: 2rem;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
    div.container .bundels article img {
      width: 15vw;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin-left: 0;
    }
    div.container .bundels article .bundel-extra p {
      font-weight: bold;
      font-size: 12px;
      margin-left: 1rem;
    }
    div.container .bundels article .bundel-extra img {
      margin: auto;
    }
    div.container .bundels article .bundel-information div {
      -webkit-box-pack: unset;
          -ms-flex-pack: unset;
              justify-content: unset;
    }
    div.container .bundels article .bundel-information div p {
      margin-right: 1rem;
    }
    .cart-page table div.img img {
      display: none;
    }
    .cart-page table tr td:nth-last-child(2), .cart-page table tr th:nth-last-child(2) {
      display: none;
    }
    .cart-page .cart-buttons button {
      display: none;
    }
    .cart-page th {
      display: none;
    }

    section.products .products article.margin-bottom {
      margin-bottom: 375px;
    }
  }